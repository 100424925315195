@import 'src/styles/imports';

.filter-dropdown {
  position: relative;
  z-index: 10;
  display: inline-block;
  min-width: 180px;
  margin-left: 1em;

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    margin-top: 1em;
    margin-right: 0;
    margin-bottom: 3em;
    margin-left: 0;
  }

  // Position the dropdown directly below the header
  :global(.suggestions-container) {
    box-sizing: border-box;
    width: 100%;
    min-width: 180px;
    max-width: 300px;
  }
}

.dropdown-header {
  font-size: 14px;
  display: flex;
  box-sizing: border-box;
  height: 100%;
  max-height: 50px;
  padding: 1em;
  padding-left: 2em;
  border: 1px solid $color-grey-5;
  border-radius: var(--themes-buttons-border-radius);
  background-color: $color-white;
  font-family: $font-family-sans-serif;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &.active {
    border-color: var(--themes-colors-secondary-focus-border-color);
  }

  span {
    font-weight: $font-weight-semibold;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    border-color: var(--themes-colors-secondary-focus-border-color);
    margin-left: 0.5em;
  }
}

// Commercial styling variant
.is-commercial .dropdown-header {
  border-radius: $border-radius-large;
}
