@import 'src/styles/imports';

.suggestions-container {
  position: absolute;
  z-index: $z-index-dropdown;
  top: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: fit-content;
  min-width: 200px;
  max-width: 450px;
  border: 1px solid $color-grey-1;
  border-radius: var(--themes-buttons-border-radius-medium);
  background-color: $color-white;

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    max-width: 100%;
  }
}

.suggestion {
  overflow-y: hidden;
  padding: 1em;
  margin: 1em;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    border-radius: var(--themes-buttons-border-radius-medium);
    background-color: $color-grey-1;
  }

  &.active {
    &::after {
      content: '✓';
      float: right;
      font-family: system-ui, sans-serif;
    }
  }
}
